.input-select {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
}

.input-field {
    -webkit-appearance: none;
    appearance: none;
    -webkit-border-radius:0px; 
    border-radius: 0;
}

.select-container {
    width: 100%;
    position: relative;

    .icon--down {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.residence-input {
    position: relative;
    width: 100%;

    .residence-label {
        padding: 0 !important;
        margin: 0 !important;
        position: absolute;
        top: 6px;
        color: #000000;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
    }

    input {
        max-width: 100%;
        padding-left: 76px !important;
    }
}

.form--disabled {
    input, select {
        color: #CCCCCC !important;
        opacity: 1 !important;
    }
}