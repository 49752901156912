/* Graphie */
@import url("https://use.typekit.net/hom6irb.css");

/* Josefin Sans */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500&display=swap");
/* Philosopher */
@import url("https://use.typekit.net/xcu1jzs.css");

//Main font
@font-face {
  font-family: "Futura Pt";
  src: url("./fonts/Adobe/ACaslonPro-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Pt";
  src: url("./fonts/Adobe/ACaslonPro-Semibold.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Pt";
  src: url("./fonts/Adobe/ACaslonPro-Bold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// Adobe Caslon Pro
@font-face {
  font-family: "Adobe Caslon Pro";
  src: url("./fonts/Adobe/ACaslonPro-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adobe Caslon Pro";
  src: url("./fonts/Adobe/ACaslonPro-Semibold.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adobe Caslon Pro";
  src: url("./fonts/Adobe/ACaslonPro-Bold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/Proxima/Proxima-Nova-Font.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Domaine Sans Display";
  src: url("./fonts/Domaine Sans Display Regular Regular.ttf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./fonts/Suisse Int'l.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./fonts/SuisseIntl-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@import "./common";

@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.css";

$utilities: map-get-multiple(
  $utilities,
  (
    "float",
    "text-transform",
    "display",
    "justify-content",
    "flex-direction",
    "text-align",
    "position",
    "height",
    "padding-y"
  )
);
@import "../node_modules/bootstrap/scss/utilities/api";

// Pretty checkbox
@import "~pretty-checkbox/src/pretty-checkbox.scss";
@import "~@fancyapps/ui/dist/fancybox.css";

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
button,
span,
p,
div,
li {
  font-family: Suisse Intl;
}

.list-group {
  &-item {
    opacity: $list-group-item-color-scale;
    cursor: pointer;

    &.active {
      font-weight: 500;
      opacity: 1;
    }

    &:hover {
      //color: pointer;
    }

    font-weight: 100;
    font-size: 14px;

    &-unit-list {
      padding-bottom: 30px !important;
    }

    &-unit-content {
      font-size: 14px;
      font-family: "Proxima Nova";
      line-height: 2;

      &.inactive {
        opacity: 0.3;
      }
    }

    .level-title {
      color: #242422;
      -webkit-text-stroke-width: 0.25;
      -webkit-text-stroke-color: #242422;
      font-family: "Suisse Intl";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      margin-bottom: 25px !important;
    }

    ul {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      > li {
        list-style: none;
        color: #242422;
        /* ✓Links and Categories */
        font-family: "Suisse Intl";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1; /* 214.286% */
        letter-spacing: 1.4px;
      }
    }
  }

  &-heading {
    text-transform: uppercase;
    font-weight: 400;
    opacity: 0.875;
  }
}

.d-flex-column {
  @extend .d-flex;
  @extend .flex-column;

  & > * {
    flex-shrink: 0;
  }
}

.title,
.f-title,
.card-title {
  font-weight: bold;
  text-transform: uppercase;
}

.btn-text {
  color: #000000;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #000000;
  }
}
.private-pool,
.bedroom-item {
  .pretty {
    width: 61px;
  }
  width: 61px;
}
.btn-text-light {
  color: #01344b;
  font-size: 16px;
  font-family: Adobe Caslon Pro;
  font-weight: 600;
  text-transform: uppercase;

  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #01344b;
  }
}

.side-card {
  height: calc(100vh - 200px);
  border: none;
  border-radius: 0;
  position: absolute;
  z-index: 100;
  top: 100px;
  width: 300px;
  padding: 20px;
  background: rgba(30, 30, 30, 0.9);

  h2 {
    position: relative;
  }

  h2::before {
    content: "";
    position: absolute;
    left: -1.25rem;
    margin-top: -2px;
    top: 50%;
    width: 25px;
    height: 2px;
    background-color: #ffffff;

    @media screen and (max-width: 1025px) {
      width: 15px;
    }
  }

  .card {
    box-shadow: none;
    border: 0px;
    color: #ffffff;
    background: transparent;
  }

  .card-title {
    padding: 0.5rem 1rem;
    color: #fff;
    font-weight: 300;
    font-size: 1.7rem;

    @media screen and (max-width: 1025px) {
      padding: 0.5rem 0.5rem;
    }
  }

  .btn-arcodion {
    cursor: pointer;
  }
  .wrap-btn-detail .list-group .list-group-item,
  .card-header,
  .list-group-item {
    border: none;
    padding: 0.5rem 1rem;
    color: #000000;
    font-family: "Futura PT";
    background-color: transparent;
    font-size: 14px;

    @media screen and (max-width: 1025px) {
      padding: 0.5rem 0.5rem;
    }

    &.active {
      font-weight: 400;
      opacity: 1;
      background-color: #000000;
      color: #ffffff;
    }

    &:hover {
      font-weight: 400;
      opacity: 1;
    }
  }

  .list-group-heading {
    opacity: 1;
  }

  .card-body {
    padding: 0;
    height: calc(100% - 52px);
    overflow-y: auto;
    background-color: transparent;

    .acordian {
      background: rgba(30, 30, 30, 0.9);
    }
  }

  &.left-card {
    left: 0;
    top: 90px;
    background-color: #ffffff;
  }

  &.right-card {
    right: 0;
  }
}

.title-center-bottom {
  position: absolute;
  margin-bottom: 0;
  bottom: 24px;
  left: 50vw;
  transform: translateX(-50%);
  color: #ffffff;
  z-index: 111;
  font-size: 2rem;
}

.invisible {
  opacity: 0;
}

.b-fade-text {
  * {
    animation: fadeTop forwards;
    opacity: 0;

    &:nth-child(1) {
      animation-duration: 1s;
    }

    &:nth-child(2) {
      animation-duration: 1.2s;
    }

    &:nth-child(3) {
      animation-duration: 1.4s;
    }

    &:nth-child(4) {
      animation-duration: 1.6s;
    }

    &:nth-child(5) {
      animation-duration: 1.8s;
    }

    &:nth-child(6) {
      animation-duration: 2s;
    }
  }
}

@keyframes fadeTop {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.underline {
  &-0 {
    text-decoration: none;
  }
  &-sm {
    display: inline-block;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 30%;
      height: 3px;
      background: #000;
      position: absolute;
      left: 0;
      bottom: -18px;
    }
  }
}

@each $name, $value in $colors {
  .bg-#{$name} {
    background: $value !important;
  }
}

.wrap-bottom-titles {
  bottom: 40px;
  color: #000000;
  left: 33px;
  right: 33px;

  .btn-replay {
    background-color: transparent;
    width: auto;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 18px;
    &:hover {
      background-color: #ffffff;
      color: #2d2927;
    }
    span {
      text-transform: uppercase;
      font-family: "Adobe Caslon Pro", serif;
      color: #ffffff;
      padding-top: 5px;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.56px;
      &:hover {
        color: #2d2927;
      }
    }
  }
  .svg-icon {
    border-radius: 50%;
    width: fit-content;
    border: 2px solid #fff;
    width: 45px;
    height: 45px;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
    margin-top: -45px;
    svg {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
    }
  }
}

.transparent {
  opacity: 0;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}
.opacity0 {
  opacity: 0 !important;
}

.elementToFadeInAndOut {
  opacity: 1;
  animation: fade 5s linear;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.common-button {
  color: #fff;
  text-align: center;
  /* Main page Buttons */
  font-family: Domaine Sans Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.25px;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  padding: 0;
}
.text-red {
  color: #e11736 !important;
}
.mt-10 {
  margin-top: 10px !important;
}

.pretty.p-svg .state .svg {
  width: 15px;
  height: 15px;
}
.pretty .state label:before {
  width: 15px;
  height: 15px;
  border-color: #242422;
}
.pretty .state label::after {
  width: 15px;
  height: 15px;
  border-color: #242422;
}

.dg.main {
  .folder {
    border: none;
  }
  .cr {
    div:first-child {
      display: flex;
    }
    .c {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
      input {
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;
      }
      input[type='checkbox'] {
        width: auto;
      }
      .slider {
        width: 328px;
        margin-left: 0;
      }
    }
  }
  .meshes-gui {
    width: 248px;
  }
  .meshes-gui > ul {
    overflow: scroll;
    max-height: 400px;
    padding-top: 27px;
  }
  .meshes-gui > ul > .title {
    position: fixed;
    width: 249px;
    margin-top: -27px;
  }
}