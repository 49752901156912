.wrap-booking-appointment {
  position: absolute;
  z-index: 99;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  padding-top: 160px;

  .header {
    .title {
      font-family: 'PP Hatton';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
      display: flex;
      align-items: center;
      color: #1A1A1A;
    }
  }

  .body {
    padding-top: 30px;

    .wrap-calendar {
      .title {
        font-family: 'Centra No1 TRIAL';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.125em;
        color: #1A1A1A;
        margin-bottom: 15px;
      }

      .sdp {
        width: 100%;
        padding: 0;
        outline: unset !important;
        box-shadow: none;
        --outline: none;
        background-color: #F0F0F0;
        border-radius: 5px;
        overflow: hidden;

        .sdp--header {
          position: relative;
          display: flex;
          align-items: center;

          .sdp--header__main {
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
            color: #ffffff;
            background-color: #000;
            border-radius: 5px;
          }

          .sdp--square-btn {
            position: absolute;
            border: none;
            background: #ffffff;
            box-shadow: none;

            &:first-child {
              left: 5px;
            }

            &:last-child {
              right: 5px;
            }
          }
        }

        .sdp--grid {
          grid-gap: 1px;
          gap: 1px;
          background-color: rgba(107, 93, 73, 0.2);
          margin: 0px;
          border-top: 1px solid rgba(107, 93, 73, 0.2);
          border-radius: 5px;

          .sdp--text {
            width: 100%;
            text-align: center;
            background-color: #f0f0f0;
          }

          .sdp--square-btn {
            width: 100%;
            border-radius: 0px;
            background-color: #f0f0f0;
            font-size: 13px;
            height: 2.5rem;

            &.sdp--date-btn__selected {
              background-color: #000;
              color: #ffffff;
            }
          }
        }
      }
    }

    .wrap-form {
      .title {
        font-family: 'Centra No1 TRIAL';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.125em;
        color: #1A1A1A;
        margin-bottom: 15px;
      }

      .form-control {
        border-radius: 5px;
      }

      .wrap-times {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        gap: 20px;

        .time-item {
          border: 0px;
          border-radius: 5px;
          background-color: #F0F0F0;
          padding-top: 5px;
          padding-bottom: 5px;
          &.active {
            background-color: #000;
            color: #ffffff;
          }
        }
      }
    }

    .wrap-btn {
      text-align: center;

      .btn-make-appointment {
        width: fit-content;
        border: 0px;
        background-color: #000;
        padding: 8px 15px;
        border-radius: 5px;
        font-family: 'Centra No1 TRIAL';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.125em;
        color: #FFFFFF;
      }
    }
  }
}
