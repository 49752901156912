.wrap-cms-page {
	.page-body.customers-page-body {
    margin: 0;
    padding: 100px 108px 0px 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: calc(100dvh - 131px - 80px);

		.action-edit {
			text-align: right;
      border-radius: 50px;
      background: rgba(211, 208, 209, 0.35);
      width: 31px;
      height: 10px;
      cursor: pointer;
      transition: 0.2s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
		}

    .form-switch.form-check {
      display: flex;
      align-items: center;
    }

    .form-switch,
    .form-check-input {
      margin: 0 !important;
      padding: 0;
    }

		.form-switch .form-check-input {
			background-color: #CCCCCC59;
      width: 44px;
      height: 20px;
      border: none !important;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
      box-shadow: none;
      transition: 300ms ease-in-out;
      cursor: pointer;

			&:checked {
				background-color: #000000 !important;
			}
		}

		.action-view {
			cursor: pointer;
      justify-content: flex-start;
      gap: 20px;
		}

    .content {
      color: #1A1A1A;
      font-size: 24px;
      min-width: 1150px;
      width: 100%;
      height: 100%;
      overflow: auto;

      .content-header,
      .content-row {
        display: grid;
        grid-template-columns: 15% 15% 23% 13% 12% 12% calc(10% - 35px) 35px;
      }

      .content-header {
        padding-bottom: 60px;
        padding-right: 45px;

        & > div {
          color: #000;
          font-family: 'Poppins';
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 10px;
          text-transform: uppercase;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .content-list {
        height: calc(100% - 75px);
        overflow: hidden auto;
        padding-right: 40px;

        &::-webkit-scrollbar {
          width: 4px;
        }
      }

      .content-row {
        border-bottom: 1px solid #C5C5C566;

        & > div {
          color: #000;
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 35px;
          display: flex;
          align-items: center;
        }

        .arrow {
          margin: 0 auto;
          border: none;
          padding-right: 0 !important;

          img {
            width: 11px;
          }
        }

        .url.action-copy {
          justify-content: flex-start;
          gap: 20px;
          cursor: pointer;
        }
      }
    }

    .add-btn-container {
      width: 100%;
    }
	}
}

.wrap-update-customer-modal,
.wrap-create-customer-modal,
.wrap-delete-customer-modal,
.wrap-customer-favorites-modal {
  background-color: #000;

  input::placeholder {
    opacity: 0.75;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .selector {
    width: 100%;
    position: relative;

    .icon--down {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
  }

  .selector select {
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
    border: none;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    border-bottom: 1px solid #000;
    color: #000;
    height: 23px;
    width: 100%;
    background-color: #ffffff;
    &:focus {
      outline: none;
    }
    option {
      opacity: 0.75;
      color: #000;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  
  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100vh !important;
    transition: none !important;

    .modal-content {
      width: 609px;
      position: relative;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 40px;
      gap: 50px;

      .select__menu {
        width: 200% !important;
      }
      
      .select__menu-portal {
        left: 40px !important;
        top: 380px !important;
      }

			.phone {
        display: flex;
        
				.select__indicator-separator {
					display: none;
				}

				.select__control {
					border: none;
					border-bottom: 1px solid #1A1A1A;
					border-radius: 0;
          width: 78px;
				}

      
        .select__single-value {
          color: #D3D0D1;
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%;
        }

        .select__value-container {
          padding: 0;
        }

				.mobile-input {
					padding: 0 20px;
				}

				.mobile-code {
					width: 76px;
				}

        .phone-input {
          width: 46px;
          border-bottom: 1px solid #1A1A1A;

          .flag {
            bottom: 8px;
          }
        }
			}
    }

    .modal-header {
      padding: 0 !important;
      border: none;
    }

    .modal-footer {
      padding: 0 !important;
      border: none;

			.finish {
        border: 1px solid #000;
				display: flex;
        height: 37px;
        padding: 12px 18px;
        margin: 0;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 10px;

				&:hover {
					color: #FFF;
					background-color: #000;
					cursor: pointer;
				}
			}
    }

    button.close {
      display: none;
    }

    .modal-title {
      color: #000;
      font-family: 'Poppins';
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .wrap-modal-body {
    // overflow-y: hidden;
    color: #fff;
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .wrap-form-group {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .error {
        color: #AE6537;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .form-group {
        flex: 1 0 0;
        padding-top: 13px;
      }
    }
    
    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-label {
      color: #000;
      text-align: center;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 10px;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    .form-control {
      color: #000;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
      border: none;
      border-bottom: 1px solid #1A1A1A;
      border-radius: 0;
      box-shadow: none;
			padding: 0;;
      margin: 0;
      height: 23px;

      & * {
        background-color: white;
        width: 100px;
      }
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .close-btn {
      position: fixed;
      top: 50px;
      right: 80px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }

  }
}

.wrap-delete-customer-modal {
  
  .modal-dialog {
    .modal-content {
      max-width: 396px;

      .wrap-content {
        display: flex;
        flex-direction: column;
        gap: 14px;
      }
    }

		.wrap-modal-body {
			padding: 0 !important;
			
			.confirm {
				color: #000;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal ;
			}
		}

		.modal-footer {
			div {
				display: flex;
        height: 37px;
        padding: 12px 18px;
        margin: 0;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border: 1px solid #E11736;
        color: #E11736;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

				&:hover {
					color: #FFF;
					background-color: #E11736;
					cursor: pointer;
				}
			}
		}
  }
}

.wrap-update-customer-modal {
  .modal-dialog {
    .modal-footer {
      display: flex;
      justify-content: space-between;
      margin: 0 !important;
    }

    .modal-content {
      .select__menu-portal {
        left: 40px !important;
        top: 470px !important;
      }
    }

    .delete {
      color: #CCC;
      text-align: center;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
			padding: 0;
			border: none;
			background: none;
			margin: 0;
			cursor: pointer;

      &:hover {
        color: #000;
      }
    }

		.save {
			color: #000;
			font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      border: 1px solid #000;
			padding: 12px 18px;
			margin: 0;
			cursor: pointer;
      display: flex;
      height: 37px;
      justify-content: center;
      align-items: center;

      &:hover {
        color: #FFF;
        background-color: #000;
      }
		}
  }
}

.wrap-customer-favorites-modal {
  .wrap-loading-icon {
    bottom: unset;
    left: 48%;
    top: -40%;
    animation-duration: unset;

    .ldio-h0h1lsshjn {
      div {
        background: #000;
      }
    }
  }

  .modal-dialog {
    color: black !important;

    .modal-title {
      width: 100%;
      border-bottom: 1px solid #CCC;
      padding-bottom: 10px !important;
      color: #000;
      font-family: 'Poppins';
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    .modal-content {
      width: 1160px !important;
      min-height: 224px !important;
      padding: 40px;

      .content {
        color: #1A1A1A;
        font-size: 24px;
        overflow-x: hidden;

        .content-header,
        .content-row {
          grid-template-columns: 19% 13% 12% 11% 11% 11% 11% 12%;
        }

        .content-header {
          display: grid;
          padding-bottom: 60px;

          & > div {
            color: #000;
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        .content-list {
          max-height: 221px;
          overflow: hidden scroll;
        }

        .content-row {
          display: grid;
          height: 35px;
          border-bottom: 1px solid #C5C5C566;

          .availability {
            text-transform: capitalize;
          }

          & > div {
            color: #000;
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
          }
        }

        .gallery-title {
          color: #000;
          font-family: 'Poppins';
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          padding-top: 50px;
          padding-bottom: 10px;
        }

        .gallery {
          display: flex;
          flex-direction: row;
          gap: 18px;
          padding-top: 40px;
          border-top: 1px solid rgba(45, 41, 39, 0.5);;
          width: 100%;

          .gallery-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 25px;

            img {
              width: 358px;
              height: 225px;
              object-fit: cover;
            }

            span {
              color: #000;
              font-family: 'Poppins';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
