.sessionPage {
  &.static-page-wf {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    margin-top: 0;
    height: 100dvh;
  }

  .scroll-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    color: #FFF;
    background-color: white;
    text-align: center;
    position: relative;

    .content-1 {
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .scroll-text {
        color: #FFF;
        text-align: center;

        /* New/Menu Header */
        -webkit-text-stroke-width: 0.25;
        -webkit-text-stroke-color: #FFF;
        font-family: "Suisse Intl";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        opacity: 0.6;
        top: 102px;
        position: absolute;
      }

      .main-image {
        height: 100dvh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url('/assets/images/image-post-session-0.png') lightgray 50% / cover no-repeat;
        position: absolute;
      }

      .main-text {
        color: #FFF;
        text-align: center;

        /* New/Subheader */
        -webkit-text-stroke-width: 0.25;
        -webkit-text-stroke-color: #FFF;
        font-family: "Domaine Sans Display";
        font-size: 52px;
        font-style: normal;
        font-weight: 400;
        line-height: 95%; /* 49.4px */
        letter-spacing: -0.52px;
        text-transform: uppercase;
        position: absolute;
      }

      .save-close-btn {
        color: #FFF;
        text-align: right;
        cursor: pointer;

        /* New/Button */
        font-family: "Suisse Intl";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 214.286% */
        letter-spacing: 1.4px;
        text-transform: uppercase;

        bottom: 50px;
        position: absolute;
        gap: 10px;
        display: flex;
        border-bottom: 1px solid #FFF;
        place-items: center;
      }
    }

    .ct-wrapper {
      width: 100vw;
      display: flex;
      justify-content: center;
      background: #FFF;
      border-bottom: 1px solid rgba(36, 36, 34, 0.15);
    }

    .content-preference {
      display: flex;
      width: 1366px;
      padding: 40px 109px;
      justify-content: center;
      align-items: flex-start;
      gap: 40px;

      &.reverse {
        flex-direction: row-reverse;
      }

      .divider {
        height: 682.569px;
        border-left: 1px solid rgba(36, 36, 34, 0.15);
      }

      .preference-floorplan {
        width: 534px;
        height: 682.569px;
        flex-shrink: 0;
        cursor: pointer;
      }

      .residence-info {
        display: flex;
        width: 534px;
        height: 534px;
        padding: 40px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        flex-shrink: 0;

        .r-title {
          display: flex;
          flex-direction: column;
          text-align: left;
          gap: 32px;

          color: #242422;

          /* New/Subheader */
          font-family: "Domaine Sans Display";
          font-size: 52px;
          font-style: normal;
          font-weight: 400;
          line-height: 95%; /* 49.4px */
          letter-spacing: -0.52px;
          text-transform: uppercase;
        }

        .r-content {
          display: flex;
          width: 300px;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          list-style: none;
          margin-left: 0;
          margin-bottom: 0;

          hr {
            width: 300px;
            height: 1.5px;
            opacity: 0.4;
            background: #C3E4EB;
            margin: 0;
          }

          .r-feats {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            align-self: stretch;

            .r-feats-item {
              color: #000;
              padding-left: 33px;
              text-indent: -33px;
              font-family: 'Suisse Intl';
              font-size: 16px;
              font-weight: 300;
              line-height: 24px;
              flex: 1 0 0;

              &:before {
                content: '-';
                padding-right: 12px;
                color: rgba(36, 36, 34, 0.70);
                
                /* New/Menu Header */
                font-family: "Suisse Intl";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1.2px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      
    }

    .session-images {
      background: #FDFBF6;
      width: 100vw;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .session-image-wrapper-1 {
      display: flex;
      width: 1366px;
      padding: 40px 109px;
      justify-content: center;
      align-items: flex-end;
      gap: 120px;

      .session-image-1 {
        height: 624px;
        width: 658px;
        object-fit: cover;
      }
      
      .session-image-2 {
        width: 370px;
        height: 400px;
        object-fit: cover;
      }
    }

    .session-image-wrapper-2 {
      display: flex;
      width: 1366px;
      padding: 40px 109px;
      flex-direction: column;
      align-items: center;
      gap: 90px;

      .session-image-3 {
        width: 1148px;
        height: 673px;
        object-fit: cover;
      }
      
    }

    .session-image-wrapper-3 {
      display: flex;
      width: 1366px;
      padding: 40px 109px;
      justify-content: center;
      align-items: flex-end;
      gap: 120px;

      .session-image-4 {
        width: 370px;
        height: 400px;
        object-fit: cover;
      }
      
      .session-image-5 {
        width: 658px;
        height: 624px;
        object-fit: cover;
      }
    }
    
  }
}

.close-view {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 35px 0px;
  background-color: #000;

  span {
    color: #FFF;
    text-align: center;
    font-family: 'Suisse Intl';
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
}

.session-footer-wrapper {
  display: flex;
  width: 1366px;
  padding: 70px 354px;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-color: white;
  border-top: 1px solid #F1F2F2;

  .footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .phone-number {
      color: #242422;
      opacity: 0.6;
      text-align: center;
      font-family: "Suisse Intl";
      font-size: 13px;
      font-style: normal;
      font-weight: 450;
      line-height: 135%; /* 17.55px */
      letter-spacing: 1.3px;
      text-transform: uppercase;
    }

    .addr {
      span {
        color: var(--242422, #242422);
        text-align: center;
        font-family: "Suisse Intl";
        font-size: 13px;
        font-style: normal;
        font-weight: 450;
        line-height: 135%; /* 17.55px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
      }

      .addr-text {
        opacity: 0.6;
      }

      .sale-gallery {
        -webkit-text-stroke-width: thin;
        -webkit-text-stroke-color: var(--242422, #242422);
        padding-right: 10px;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    hr {
      width: 657.067px;
      height: 1px;
      background: rgba(197, 197, 197, 0.40);
      margin: 7px 0 10px;
      padding: 0 0;
    }

    .credit {
      span {
        color: rgba(255, 255, 255, 0.60);
        text-align: center;
        font-family: 'Suisse Intl';
        font-size: 10px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1px;
      }
      img {
        width: 12px;
        height: 9px;
      }
    }
  }
}

.session.wrap-floor-plan-gallery {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;

  .close-icon {
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 1002;
    cursor: pointer;
  }

  .react-transform-wrapper {
    height: 100% !important;
    width: 100vw !important;
    .react-transform-component {
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.90);
    }

    .floorplan-item {
      width: 100%;
      height: 100%;
      object-fit: contain;

      &.img-not-found {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }

    .slick-slide {
      border: none;
    }
  }
}