.virtual-tour-modal {
    width: 100vw;
    height: calc(100dvh - 148px);
    position: absolute;
    z-index: 1000;
    top: 98px;
    right: 0;

    &.isPresentation {
        height: 100dvh;
        top: 0;
    }

    .img-panorama {
        width: 100%;
        height: 100%;
    }
}