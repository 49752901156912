.views{
  .aw-body-content{
    padding-right: 300px;
  }
}
.views-levels{
  .slick-dots{
    bottom: 160px;
  }
  .btn-switch-level{
    position: absolute;
    z-index: 99;
    display: flex;
    bottom: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;
    div{
      display: flex;
      cursor: pointer;
      .btn-switch{
        margin-left: 10px;
      }
    }
  }
  .btn-gallery.next-slide{
    right: 93px;
  }
  .btn-gallery.prev-slide{
    left: 95px;
  }
  .modal-body{
    background: #000000;
    .aw-title{
      color: #FFFFFF;
    }
    .car-floor{
      border: 1px solid #ffffff;
      span{
        color: #ffffff;
      }
    }
    .car-floor.isSelected {
      background: #ffffff;
      span{
        color: #000000;
      }
    }
  }
}
