@import '../../common.scss';

.wrap-explore-amenities {
  background: transparent;
  position: absolute;
  z-index: 9;
  top: 100px;
  height: calc(100vh - 200px);
  left: 30px;
  color: #fff;
  width: fit-content;
  overflow-y: auto;
  .wrap-list-transport {
    .card-title {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 30px;
      margin-top: 40px;
      font-weight: 700;
      color: #000;
    }
    .card-body {
      padding: 0px;
    }
    .list-transport {
      padding: 0;
      list-style-type: none;
      li {
        margin-bottom: 20px;
        .transport-item {
          width: fit-content;
          border-radius: 50%;
          svg {
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
          }
          &.active {
            path {
              fill-opacity: 1;
              transition: all .2s ease-in-out;
            }
          }
          @each $name, $value in $colors {
            &-#{$name} {
              &:hover, &:focus {
                border-color: $value;
                &:not(.active) {
                  svg path {
                    fill: $value;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.list-2col {
  column-count: 2;
  column-gap: 30px;
}
