.modal-backdrop.fade {
  opacity: 0.9 !important;
}

.wrap-user-type-modal {
  background: #000000e6;
  width: 100vw;
  height: 100vh;

  .modal-dialog-centered {
    min-height: 100% !important;
  }

  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100vh !important;
    transition: none !important;
  }

  .modal-content {
    width: 650px;
    position: static;
    border-radius: 0;
    color: #000000;
    background-color: #ffffff;

    .close-btn {
      position: absolute;
      right: 80px;
      top: 50px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }
  }

  .wrap-modal-body {
    padding: 36px 40px;
    overflow-y: hidden;
    position: static;

    .btn {
      border: #fff;
      border-width: 1px;
    }
    .modal-form__title {
      color: #002846;
      font-size: 30px;
      font-family: "Adobe Caslon Pro";
      letter-spacing: 2.1px;
      text-transform: uppercase;
      line-height: normal;

      &.underline-sm {
        &::before {
          width: 0;
          height: 0;
        }
      }
    }
    .btn-bottom-user-type {
      display: flex;
      flex-direction: row;
      button {
        border: 1px solid #59899d;
        display: flex;
        height: 37px;
        padding: 12px 18px;
        align-items: flex-start;
        background-color: transparent;
        font-size: 13px;
        font-family: "Adobe Caslon Pro";
        text-transform: uppercase;
        color: #59899d;
        letter-spacing: 0.12em;
        line-height: normal;
        font-weight: 400;
        &:hover {
          color: #ffffff;
          background: #6b8fa3;
        }
      }
      button:first-child {
        margin-right: 35px;
      }
    }
  }

  .btn-outline {
    border: 1px solid #000000;
    border-radius: 0px;
    padding: 16px 54px;
    background-color: #ffffff;
  }
}

.wrap-user-language-modal {
  background: rgba(0, 0, 0, 0.5);
  .modal-content {
    border-radius: 0px;
    background-color: #fff;
    color: #000;
  }
  width: 540px;
  height: 100px;

  .wrap-modal-body {
    padding: 36px 40px;
    overflow-y: hidden;

    .btn {
      border: #000;
      border-width: 1px;
    }
    .modal-form__title {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      .title {
        color: #002846;
        font-family: "Adobe Caslon Pro";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.1px;
        text-transform: uppercase;
      }
      .description {
        color: #2d2927;
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
      }
      &.underline-sm {
        &::before {
          width: 75px;
          background: #fff;
          bottom: -9px;
          height: 1px;
        }
      }
    }
  }

  .btn-language {
    background-color: #fff;
  }

  .btn-outline {
    border: 1px solid #fff;
    border-radius: 0px;
    width: 130px;
    background-color: #fff;
  }

  .language-buttons {
    justify-content: flex-start;
    display: flex;
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 57px;
      height: 37px;
      border: 1px solid #59899d;
      background: transparent;
      margin-right: 35px;
      span {
        font-family: "Adobe Caslon Pro", serif;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        padding-top: 5px;
        color: #59899d;
      }

      &:hover {
        background: #6b8fa3;
        span {
          color: #ffffff;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 540px !important;
  }
}

.wrap-list-user-modal {
  background: #000000e6;
  width: 100vw;
  height: 100vh;

  .bg-dark {
    background: black;
  }

  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100vh !important;
    transition: none !important;
  }

  .modal-content {
    width: 650px;
    position: static;
    border-radius: 0;
    color: #000000;
    background-color: #ffffff;

    .close-btn {
      position: absolute;
      right: 80px;
      top: 50px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }
  }

  .wrap-modal-body {
    padding: 36px 40px;
    overflow-y: hidden;
    position: static;

    .modal-form__title {
      color: #002846;
      font-size: 30px;
      font-family: "Adobe Caslon Pro";
      letter-spacing: 2.1px;
      text-transform: uppercase;
      &.underline-sm {
        &::before {
          width: 0;
          height: 0;
        }
      }
    }
    .modal-form__sub-title {
      color: #2d2927;
      font-size: 14px;
      font-family: "Proxima Nova";
      button {
        background: #fff;
        color: #6b8fa3;
        font-weight: normal;
        border: none;
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        line-height: 125%;
      }
    }
    .wrapper-list-user {
      margin-bottom: 50px;
      .first-last-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .form-info {
        display: flex;
        flex-direction: column;
        width: 48%;
        label {
          color: rgba(45, 41, 39, 0.5);
          text-align: left;
          font-size: 14px;
          font-family: "Proxima Nova";
          line-height: 125%;
        }
        input {
          padding-left: 0;
          border: none;
          border-bottom: 1px solid #2d2927;
          font-family: "Proxima Nova";
          font-size: 14px;
          text-align: left;
          height: 50px;
          margin-top: 5px;
          background-color: #ffffff;
          &:focus {
            outline: none;
          }
        }
        input::placeholder {
          color: #d3d0d1;
        }
      }
    }
    .wrap-button-list-user {
      align-items: center;

      button {
        background-color: transparent;
        text-transform: uppercase;
        border: 1px solid #59899d;
        color: #59899d;
        font-size: 13px;
        font-family: "Adobe Caslon Pro";
        letter-spacing: 1.56px;
        padding: 12px 18px;
        height: 37px;
        line-height: normal;
      }

      .submit-button span {
        background-color: transparent;
        text-transform: uppercase;
        border: 1px solid #59899d;
        color: #59899d;
        font-size: 13px;
        font-family: "Adobe Caslon Pro";
        letter-spacing: 1.56px;
        padding: 12px 18px;
      }

      button:last-child {
        font-family: Proxima Nova;
        background-color: transparent;
        height: 50px;
        color: rgba(45, 41, 39, 0.5);
        border: none;
        padding: 0;
        &:hover {
          color: #59899d;
        }
      }
    }
  }

  .wrap-user-list {
    padding: 8px 16px 8px 6px;
    overflow-y: scroll;
    height: 200px;
    border-style: groove;
    color: #fff;

    p {
      padding-left: 10px;
      padding-top: 12px;
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;

      &:active {
        background-color: #7a7a7a;
        color: #eeeeee;
      }

      &:focus {
        background-color: #000;
        color: #eeeeee;
      }
    }

    .cursor-pointer {
      background-color: #000;
      color: #fff;
    }

    .cursor-pointer:active {
      background-color: #7a7a7a;
      color: #eeeeee;
    }

    .cursor-pointer:focus {
      background-color: #7a7a7a;
      color: #eeeeee;
    }
  }

  .wrap-button {
    margin-top: 16px;
    button {
      background: #000;
      color: #fff;
      font-weight: normal;
      border: 1px solid #000;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration-line: underline;
      padding: 0;
      width: auto;
    }
  }

  ::-webkit-scrollbar {
    width: 30px;
  }

  ::-webkit-scrollbar-track {
    border-left: 12px solid #eeeeee;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-left: 12px solid #d5d5d5;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  .create-profile {
    font-family: "Proxima Nova";
  }
}

.wrap-create-user-cms-residence-modal {
  width: 100vw;
  height: 100vh;

  .bg-dark {
    background: black;
  }

  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100vh !important;
    transition: none !important;
  }

  .modal-content {
    width: 650px;
    position: static;
    border-radius: 0;
    color: #000000;
    background-color: #ffffff;

    .close-btn {
      position: absolute;
      right: 80px;
      top: 50px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }
  }

  .wrap-modal-body {
    padding: 36px 40px;
    overflow-y: hidden;
    position: static;

    .modal-form__title {
      color: #002846;
      font-size: 30px;
      font-family: "Adobe Caslon Pro";
      letter-spacing: 2.1px;
      text-transform: uppercase;
      &.underline-sm {
        &::before {
          width: 0;
          height: 0;
        }
      }
    }
    .modal-form__sub-title {
      color: #2d2927;
      font-size: 14px;
      font-family: "Proxima Nova";
      button {
        background: #fff;
        color: #6b8fa3;
        font-weight: normal;
        border: none;
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        line-height: 125%;
      }
    }
    .wrapper-list-user {
      margin-bottom: 50px;
      padding-top: 40px;

      .first-last-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .form-info {
        display: flex;
        flex-direction: column;
        width: 48%;
        label {
          color: rgba(45, 41, 39, 0.5);
          text-align: left;
          font-size: 14px;
          font-family: "Proxima Nova";
          line-height: 125%;
        }
        input {
          padding-left: 0;
          border: none;
          border-bottom: 1px solid #2d2927;
          font-family: "Proxima Nova";
          font-size: 14px;
          text-align: left;
          height: 50px;
          margin-top: 5px;
          background-color: #ffffff;
          &:focus {
            outline: none;
          }
        }
        input::placeholder {
          color: #d3d0d1;
        }
      }
    }
    .wrap-button-list-user {
      align-items: center;

      .footer {
        display: flex;
        justify-content: space-between;
        width: 100%;

        span {
          display: flex;
          align-items: center;
          padding-top: 5px;
          cursor: pointer;
        }

        button {
          width: 75px !important;
          color: #6b8fa3;
          font-family: Adobe Caslon Pro;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.56px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          padding-top: 15px;
        }
      }

      button {
        background-color: transparent;
        text-transform: uppercase;
        border: 1px solid #59899d;
        color: #59899d;
        font-size: 13px;
        font-family: "Adobe Caslon Pro";
        letter-spacing: 1.56px;
        padding: 12px 18px;
        height: 37px;
        line-height: normal;

        &.text-uppercase {
          width: 100%;
        }
      }
    }
  }

  .wrap-user-list {
    padding: 8px 16px 8px 6px;
    overflow-y: scroll;
    height: 200px;
    border-style: groove;
    color: #fff;

    p {
      padding-left: 10px;
      padding-top: 12px;
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;

      &:active {
        background-color: #7a7a7a;
        color: #eeeeee;
      }

      &:focus {
        background-color: #000;
        color: #eeeeee;
      }
    }

    .cursor-pointer {
      background-color: #000;
      color: #fff;
    }

    .cursor-pointer:active {
      background-color: #7a7a7a;
      color: #eeeeee;
    }

    .cursor-pointer:focus {
      background-color: #7a7a7a;
      color: #eeeeee;
    }
  }

  .wrap-button {
    margin-top: 16px;
    button {
      background: #000;
      color: #fff;
      font-weight: normal;
      border: 1px solid #000;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration-line: underline;
      padding: 0;
      width: auto;
    }
  }

  ::-webkit-scrollbar {
    width: 30px;
  }

  ::-webkit-scrollbar-track {
    border-left: 12px solid #eeeeee;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-left: 12px solid #d5d5d5;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  .create-profile {
    font-family: "Proxima Nova";
  }
}

.wrap-end-guide-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 90;
  background-color: #000;
  opacity: 1;

  .modal-end-guide {
    background-color: #fff !important;
    position: absolute;
    top: 120px;
    left: 60px;
    width: calc(100% - 120px);
    height: calc(100% - 240px);

    .modal-end-guide-body {
      height: 80%;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 10px;
        margin-right: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #eeeeee;
      }

      &::-webkit-scrollbar-thumb {
        background: #d4d4d4;
        min-height: 40px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;

      border: unset;
      background-color: unset;
      // color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.5;
      padding: 16px;
      line-height: 15px;
      font-weight: 300;

      &:hover {
        background-color: #fff;
      }
    }
  }
}

.wrap-not-found {
  background-color: rgba(0, 0, 0, 0.8);
  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100vh !important;
    transition: none !important;

    .modal-content {
      width: 550px;
      position: static;
      border-radius: 0;
      background-color: transparent;
      color: #000000;
      border-radius: 0;
      padding: 20px 24px;
    }
  }
  .wrap-modal-body {
    padding: 16px;
    position: static;

    .modal-not-found {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        color: #fff;
        text-align: center;

        /* Header */
        font-family: Domaine Sans Display;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 43.2px */
        text-transform: uppercase;
        margin-bottom: 14px;
      }
      .sub {
        color: #fff;
        text-align: center;

        /* Body */
        font-family: Suisse Intl;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 150% */
      }
      button {
        color: #fff;
        text-align: center;
        /* ✓Links and Categories */
        font-family: Suisse Intl;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 214.286% */
        letter-spacing: 1.4px;
        text-transform: uppercase;
        margin-top: 40px;
      }
    }
  }
}
