/** @format */

#loading-screen {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
  opacity: 1;
  transition: 1s opacity;
  display: flex;
  align-items: flex-end;
  color: #fff;
  text-align: center;
  background-color: black;

  img.play {
    position: absolute;
    top: calc(50% - 80px);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img.intro {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .disclaimer {
    text-align: center;
    position: absolute;
    bottom: 25px;
    width: calc(100% - 300px);
    left: 150px;
    z-index: 100;

    span {
      font-family: Adobe Caslon Pro;
      font-size: 18px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.015em;
      color: white;
    }
  }

  #loading-cube-wrapper {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  #wrap-cube-model {
    position: absolute;
    top: 0px;
    z-index: 1;

    &.disable-click {
      pointer-events: none;
    }
  }

  .slick-dots {
    bottom: 50px;

    li {
      margin: 0 2px;

      button:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #fff;
        }
      }
    }
  }

  .slick-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
  }

  .slick-arrow {
    display: none !important;
  }

  .wrap-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.center-center {
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    img {
      height: 100vh;
    }
  }

  .wrap-intro {
    &.hide {
      display: none;
    }

    &.show {
      display: unset;
    }
  }

  &.hide {
    display: none;
  }

  .intro-content {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 130px 0;
    img {
      width: 291px;
      height: 262px;
      margin-top: -100px;
    }
    .loading-button {
      height: 165px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .loading-icon {
        height: 102px;
        width: 102px;
      }
      .wrap-loading-icon {
        display: inline-block;
        overflow: hidden;
        background: none;
        position: relative;
        bottom: 0;
      }
      .wrap-button {
        margin-top: -170px;
        display: flex;
        align-items: center;
        button {
          background: transparent;
          color: #ffffff;
          padding: 0;
          padding-bottom: 5px;
          color: #fff;
          text-align: center;
          font-family: Suisse Intl;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 2.25px;
          text-transform: uppercase;
          width: fit-content;
          border: none;
          border-bottom: 1px solid #fff;
        }
        img {
          width: 32px;
          height: 10px;
          margin-left: 10px;
          margin-bottom: 4px;
          margin-top: 0;
        }
      }
    }
  }

  .wrap-slide-loading {
    transition: all 1s;
    .btn-skip-video {
      left: 50%;
      transform: translateX(-50%);
      bottom: 106px;
      position: absolute;
      display: flex;
      gap: 10px;
      background: transparent;
      border: none;

      span {
        color: #fff;
        font-family: "Suisse Intl";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 214.286% */
        letter-spacing: 1.4px;
        text-transform: uppercase;
      }
    }
    .wrap-content {
      position: absolute;
      bottom: 50px;
      left: 50px;
      text-align: left;

      .wrap-line {
        display: flex;
        border-top: 1px solid #fff;

        .left,
        .right {
          padding: 10px;
        }

        .left {
          width: 75%;
          border-right: 1px solid #fff;
        }

        .right {
          width: 25%;
          letter-spacing: 0.2rem;
        }
      }
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }

    &.show {
      opacity: 1;
      pointer-events: unset;
      height: 100%;
      width: 100%;
    }
  }
}

#intro-video {
  // position: fixed;
  // top: 0;
  // left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

#select-profile-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  color: #242422;
  padding: 80px 110px 80px 110px;
  display: flex;
  overflow-y: scroll;
  gap: 40px;
  padding-left: calc(50vw - 534px - 40px);

  @media (width: 3840px) {
    /* Your CSS */
    justify-content: center;
  }

  img {
    @media (width: 3840px) {
      /* Your CSS */
      height: 100vh;
      width: auto;
    }
  }

  .divider {
    height: 100%;
    width: 1px;
    background-color: #24242226;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    width: 534px;
    align-items: start;
    height: 100%;
    position: relative;
    justify-content: space-between;

    @media (width: 3840px) {
      /* Your CSS */
      height: 100vh;
      width: 1363px;
      margin-right: 200px;
    }

    .title-wrapper {
      height: 307px;
      text-align: left;
      width: 100%;
      padding-top: 42px;

      .title {
        font-family: Domaine Sans Display;
        font-size: 75px;
        font-weight: 400;
      }
    }

    .select-customer {
      border-top: 1px solid #24242226;
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
      padding: 20px 0px 30px 0;

      p {
        font-weight: 400;
        font-size: 22px;
        color: #000;
        letter-spacing: -1px;
        font-family: "Suisse Intl";
        text-align: left;
      }

      .input-group {
        display: grid;
        column-gap: 15px;
        row-gap: 25px;
        grid-template-columns: auto auto;
        margin-bottom: 30px;

        .error-message {
          font-weight: 400;
          font-size: 12px;
          color: #c02b0a;
          font-family: "Suisse Intl";
          margin-top: 5px;
          letter-spacing: 0.5px;
        }

        .error {
          border: 1px solid #c02b0a;
        }

        .input-wrapper {
          position: relative;

          &.email-input {
            grid-column: 1 / 3;
          }

          &.sales-agent {
            grid-column: 1 / 3;
          }
        }

        .place-holder {
          position: absolute;
          left: 20px;
          top: 7px;
          font-weight: 400;
          font-size: 8px;
          color: #24242299;
        }
      }

      .select__menu-list {
        padding: 0 !important;

        .select__option {
          font-family: "Proxima Nova";
        }

        .css-9gakcf-option,
        .css-1n7v3ny-option {
          background-color: #f4f4f4;
          color: #000;
        }
      }

      .wrap-select-sales {
        &:focus-visible {
          outline: none;
        }

        height: 64px;
        border-radius: 0;
        border: none;
        background: #f4f4f4;
        outline: none;
        box-shadow: none;

        .select__control {
          border: unset;
          box-shadow: unset;
        }

        svg {
          color: #242422;
        }

        .select__indicator-separator {
          display: none;
        }

        .select__indicators {
          position: absolute;
          right: 0;
          height: 100%;
        }

        .select__value-container {
          height: 100%;
          padding: 0;
          width: 100%;
          height: 64px;
          border: none;
          background: #f4f4f4;

          .select__single-value {
            font-size: 12px;
            font-weight: 400;
            color: #242422;
            letter-spacing: 0.5px;
            font-family: "Suisse Intl";
            text-align: left;
            padding-left: 20px;
          }

          .select__input-container {
            height: 64px;
            margin: 0;
            padding: 0;
          }
        }
      }

      input {
        width: 100%;
        height: 64px;
        border: none;
        background: #f4f4f4;
        padding-left: 20px;

        &::placeholder {
          font-size: 12px;
          font-weight: 400;
          color: #242422;
          letter-spacing: 0.5px;
          font-family: "Suisse Intl";
        }

        &:focus-visible {
          outline: none;
        }
      }

      .name-wrapper {
        padding: 0;
        width: 100%;
        margin-top: 1rem;
        height: 250px;
        overflow: scroll;

        li:first-child {
          border-top: 1px solid #24242212;
        }

        li {
          list-style: none;
          display: block;
          width: 100%;
          text-align: start;
          height: 40px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #24242212;
          padding-left: 20px;
        }

        .active {
          border: 2px solid #242422 !important;
        }
      }

      .select-profile {
        width: 100%;
        height: 60px;
        background: #000;
        color: #fff;
        border: none;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25px;
      }

      .button-group {
        display: flex;

        button {
          border: none;
          background: transparent;
          font-size: 12px;
          font-weight: 400;
          padding: 0;

          &:first-child {
            margin-right: 24px;
          }
        }
      }
    }
  }
}

#intro-video-2 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100vw;
  transform: translate(0, -50%);
  object-fit: cover;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#intro-img {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
}

.wrap-text {
  margin-bottom: 30px;
  text-align: start;
  font-size: 48px;
  letter-spacing: 0.055em;
  line-height: normal;
}

.disable-btn {
  opacity: 0;
  pointer-events: none;
}
