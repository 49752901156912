/** @format */

.renders-content {
  .card-renders {
    background-color: #ffffff;
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.25));
    height: 130px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

    .title {
      position: absolute;
      bottom: 15px;
      left: 15px;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 116%;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #fff;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infinity {
    background-color: #e3e3e3;
  }
}

.static-page-wf.gallery-landing {
  overflow-y: hidden;
}

// .gallery-content {
//   height: 115%;
//   overflow: overlay;

//   @media screen and (max-width: 1366px) {
//     height: 79.5%;
//   }
// }

.films-content {
  padding: 100px 108px 100px 108px;
  background-color: #fcfcfc;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  .card-films {
    width: 48%;
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.25));
    height: 458px;
    background-color: #ffffff;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
    .sub {
      size: 22px;
      font-weight: 600;
      color: #fff;
    }

    .title {
      size: 31px;
      font-weight: 700;
      color: #fff;
    }

    .divider {
      height: 1.5px;
      width: 60px;
      background: #fff;
      margin-top: 13px;
      margin-bottom: 39px;
    }

    img {
      width: 75px;
      cursor: pointer;
    }
  }

  .infinity {
    background-color: #e3e3e3;
  }

  .wrap-video-film {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    video {
      width: 100vw;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.floor-plans-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 100px;
  overflow-y: scroll;

  .floor-plans-landing {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .header-content {
      color: #000;
      text-align: center;
      font-family: "Suisse Intl";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 13.9px;
      text-transform: uppercase;
    }

    .line {
      width: 20px;
      height: 1px;
      background: #242422;
      margin-top: 16px;
      margin-bottom: 130px;
    }
  }

  .floor-plans-list {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width: 1030px;

    .floor-plans-buttons {
      display: flex;
      width: 250px;
      height: 250px;
      padding: 12px 18px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border: 1px solid rgba(36, 36, 34, 0.15);
      flex-direction: column;
      cursor: pointer;
      .floor-plans-buttons-ph {
        color: #242422;
        font-family: "Domaine Sans Display";
        font-size: 52px;
        font-style: normal;
        font-weight: 400;
        line-height: 95%; /* 49.4px */
        letter-spacing: -0.52px;
        text-transform: uppercase;
      }

      .floor-plans-buttons-title {
        color: #242422;
        text-align: center;
        /* New/Menu Header */
        font-family: "Suisse Intl";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.2px;
        text-transform: uppercase;
      }

      .floor-plans-buttons-number {
        color: #242422;
        text-align: center;
        /* New/Subheader */
        -webkit-text-stroke-width: 0.25;
        -webkit-text-stroke-color: #242422;
        font-family: "Domaine Sans Display";
        font-size: 52px;
        font-style: normal;
        font-weight: 400;
        line-height: 95%; /* 49.4px */
        letter-spacing: -0.52px;
        text-transform: uppercase;
        margin-top: 16px;
      }
    }
  }

  .btn-floor {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 41px;
    margin-top: auto;

    .btn-floor-text {
      color: #000;
      text-align: right;

      /* ✓Links and Categories */
      font-family: "Suisse Intl";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 214.286% */
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }
  }

  .content-plans {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .header-content {
      color: #000;
      text-align: center;
      /* New/Subheader */
      font-family: "Domaine Sans Display";
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 95%; /* 49.4px */
      letter-spacing: -0.52px;
      text-transform: uppercase;
      margin-top: 70px;
    }

    .plans-body {
      width: 100%;
      height: 436.546px;
      flex-shrink: 0;
      position: relative;
      margin-bottom: 100px;

      &.floor-2 {
        margin-top: 100px;
      }

      &.floor-3 {
        margin-top: 73.21px;
      }

      &.floor-4 {
        margin-top: 78px;
      }

      &.floor-5 {
        margin-top: 71.76px;
      }

      &.floor-6 {
        margin-top: 73.2px;
      }

      &.floor-7 {
        margin-top: 78.7px;
      }

      &.floor-PH {
        margin-top: 77.44px;
      }
      // img {
      //   height: 328px;
      //   width: calc(328px * 2.34);
      // }
    }
  }

  .wrap-floor-plan-gallery {
    z-index: 1000;
    .floorplan-item {
      height: 100%;
    }
  }
}

.aw-body-content {
  width: 100%;
  padding: 70px 200px 0 273px;

  .bottom-top {
    position: fixed;
    height: 1px;
    bottom: 80px;
    width: calc(100% - 216px);
    left: 108px;
    background: #2d292780;
  }

  .aw-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 600px;

    .car-floor {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 1px solid #6b8fa3;
      cursor: pointer;
      width: 110px;
      height: 110px;

      &:hover {
        background: #6b8fa3;
        span {
          color: #fff;
        }
      }

      span {
        color: #6b8fa3;
        font-size: 30px;
        font-family: Adobe Caslon Pro;
        letter-spacing: 2.1px;
        text-transform: uppercase;
      }
    }
  }
}

.infinity {
  background-color: #e3e3e3;
}

.modal-floor {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20000;
  justify-content: center;
  align-items: center;
  display: flex;

  .modal-body {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .modal-close {
      padding: 50px 50px 0 0;
      text-align: right;

      .modal-close-icon {
        cursor: pointer;
      }
    }

    .modal-floor-container {
      margin-top: 166px;
    }
  }

  .header-content {
    margin-top: 0;
  }

  .aw-body-content {
    .car-floor.isSelected {
      border: 1px solid #6b8fa3;
      background: #6b8fa3;

      span {
        color: #ffffff;
      }
    }
  }
}

.modal-view-plans {
  .btn-close-modal {
    top: 40px;
  }

  .modal-base-content {
    height: 100vh !important;
    min-width: 100vw !important;

    .modal-base-body {
      height: 100% !important;
      //background-color: #4e4d4b;
      overflow-x: hidden;
      padding-left: 40px !important;
      padding-right: 40px !important;

      .view-plans {
        height: 100%;

        .slider-floor-plan {
          .img-plans-content {
            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }

        .slick-slider {
          .slick-arrow {
            width: 25px;
            // height: 50px;
          }

          .slick-next {
            transform: rotate(180deg);
            top: 45%;
          }
        }
      }
    }
  }
}

.wrapper-media-image {
  padding-top: 60px;
  padding-bottom: 140px;

  img {
    object-fit: cover;
    cursor: pointer;
  }

  .top-0-image {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .top-0-left {
      display: flex;
      flex-direction: column;

      span {
        color: #01344b;
        font-size: 48px;
        font-family: "Adobe Caslon Pro";
        letter-spacing: 2.64px;
        text-transform: uppercase;
        margin-left: 200px;
        margin-top: 73px;
        margin-bottom: 56px;
        max-width: 550px;
      }

      img:last-child {
        margin-left: 137px;
      }
    }

    .top-0-right {
      padding-top: 160px;
    }
  }

  .top-1-image {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 115px;

    .top-1-left {
      padding-top: 100px;
    }

    .top-1-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      img:last-child {
        margin-right: 290px;
        margin-top: 74px;
      }
    }
  }

  .body-img {
    margin-top: 136px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-img {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 90px;

    .bottom-right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 60px;

      .bottom-1-right {
        display: flex;
        flex-direction: column;
        margin-right: 100px;
        justify-content: flex-end;
        align-items: flex-end;

        span {
          color: #01344b;
          font-size: 48px;
          font-family: "Adobe Caslon Pro";
          letter-spacing: 2.64px;
          text-transform: uppercase;
          max-width: 500px;
          margin-bottom: 100px;
        }
      }
    }
  }
}

.wrap-fancy-close {
  position: fixed;
  z-index: 1055;
  top: 50px;
  right: 50px;
  cursor: pointer;
}

.fancy-btn-prev {
  position: fixed;
  z-index: 1000;
  width: 40px;
  height: 26px;
  top: calc(50% - 13px);
  left: 100px;
  cursor: pointer;
  img {
    width: 60px;
    height: 60px;
    transform: rotate(180deg);
  }
}
.fancy-btn-next {
  position: fixed;
  z-index: 1000;
  width: 40px;
  height: 26px;
  top: calc(50% - 13px);
  right: 100px;
  cursor: pointer;
  img {
    width: 60px;
    height: 60px;
  }
}

.pure-fancy-box > .fancybox__toolbar {
  display: none !important;
}
.pure-fancy-box .fancybox__slide {
  padding: 0px !important;
  background-color: black !important;
}
.fancybox__image {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}

.image-static-fancy-box > .fancybox__toolbar {
  display: none !important;
}
.image-static-fancy-box {
  .fancybox__slide {
    padding: 0 !important;
  }
  .fancybox__content {
    width: 100% !important;
    height: 100% !important;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.media-images-content {
  margin: 100px 108px;

  .media-images-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .media-images-content-wrapper-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    // justify-content: center;
    align-items: center;
    width: 1150px;
    img {
      cursor: pointer;
    }
  }

  .media-item {
    position: relative;
    cursor: pointer;
  }

  .media-item-text {
    position: absolute;
    left: 10px;
    bottom: 10px;

    color: #fff;
    font-family: Roos St Regis Text;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.06px;
  }

  .ct-image-1 {
    width: 575px;
    height: 350px;
  }

  .ct-image-2 {
    width: 531px;
    height: 350px;
  }

  .ct-image-3 {
    width: 1120px;
    height: 630px;
  }
}

.exteriors {
  .media-item-0-0 {
    width: 518px;
    height: 363px;
  }

  .media-item-0-1 {
    height: 363px;
    max-width: 627px;
    flex: 1 0 0;
  }
  .media-item-1-0 {
    height: 414px;
    max-width: 554px;
    flex: 1 0 0;
  }
  .media-item-1-1 {
    width: 591px;
    height: 414px;
  }
}

.amenities {
  .media-item-0-0 {
    width: 518px;
    height: 363px;
  }

  .media-item-0-1 {
    height: 363px;
    max-width: 627px;
    flex: 1 0 0;
  }
  .media-item-1-0 {
    width: 765px;
    height: 465px;
  }
  .media-item-1-1 {
    width: 378px;
    height: 465px;
  }
}

.interiors {
  .media-item-0 {
    height: 496px;
    width: 100%;
    flex: 1 0 0;
  }

  .media-item-1 {
    width: 351px;
    height: 496px;
  }

  .media-item-2 {
    width: 100%;
    height: 718.914px;
  }
}

.container-image {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.popup-gallery-navigation.right {
  right: 109px !important;
}

.popup-gallery-navigation.left {
  left: 109px !important;
}

.media-images-gallery {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.gallery-amenities-tab {
  .amenities-coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    margin-top: 240px;

    .line {
      width: 1px;
      height: 100px;
      opacity: 0.4;
      background: #000;
    }
    .text {
      width: 816px;
      color: #000;
      text-align: center;

      /* Page header */
      font-family: Domaine Sans Display;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 57.6px */
      text-transform: uppercase;
    }
  }
}

.gallery-exteriors-tab {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .exterior-image-row {
    gap: 5px;
    display: flex;
  }
}

.lazy-load-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}