.faq-page-container {
  height: calc(100dvh - 80px); // 80px is top nav height
}
.faq-page-body {
  margin-left: 108px;
  display: flex;
  flex-direction: column;
}

.wrap-request-support-modal {
  input::placeholder {
    color: #000000;
  }

  .font-poppins {
    font-family: Poppins !important;
  }

  .mb-5 {
    margin-bottom: 5px !important;
  }

  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100svh !important;
    transition: none !important;

    .modal-content {
      width: 609px;
      position: relative;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 40px;
      gap: 50px;

      .select__input-container {
        margin: 0;
        padding: 0;
      }

      .select__control {
        outline-width: 0 !important;
        box-shadow: none;

        .select__placeholder,
        .select__single-value {
          margin-left: 0;
          color: #000;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .select__single-value {
          color: #2D2927;
        }
      }

      .select__menu-portal {
        left: 40px !important;
        top: 200px !important;
      }

      .select__menu {
        border: none;
      }

      .select__option {
        background: #fff;
        color: #000;
        font-family: Proxima Nova;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #D3D0D1;

        &:hover {
          background: #01344B;
          color: #fff;
        }
      }

      .select__indicator-separator {
        display: none;
      }

      .select__dropdown-indicator {
        padding-right: 0;
        color: #000000;
      }

      .select__control {
        border: none;
        border-bottom: 1px solid #1A1A1A;
        border-radius: 0;
      }

      .select__value-container {
        padding: 0;
      }
    }

    .modal-header {
      padding: 0 !important;
      border: none;
    }

    .modal-footer {
      padding: 0 !important;
      border: none;

      .finish {
        color: #000000;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.56px;
        text-transform: uppercase;
        padding: 7.5px 18px;
        width: 100%;
        border: 1px solid #000000;
        text-align: center;
        cursor: pointer;
      }
    }

    button.close {
      display: none;
    }

    .modal-title {
      color: #000000;

      /* Header - medium */
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  .wrap-modal-body {
    overflow-y: hidden;
    color: #fff;
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .wrap-form-group {
      display: flex;
      flex-direction: row;
      gap: 33px;

      .error {
        color: #AE6537;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .form-group {
        flex: 1 0 0;
        padding-top: 13px;
        display: flex;
        flex-direction: column;
      }
    }

    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }

    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-label {
      align-self: flex-start;
      margin-bottom: 0;
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;

      /* ✓CMS Header */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
    }

    .form-control {
      margin-top: 10px;
      color: #000;
      leading-trim: both;
      text-edge: cap;

      /* ✓CMS */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      /* 17.5px */
      border: none;
      border-bottom: 1px solid #1A1A1A;
      border-radius: 0;
      box-shadow: none;
      padding: 0 0 13px 0;

      & * {
        background-color: white;
        width: 100px;
      }
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .close-btn {
      position: fixed;
      top: 50px;
      right: 80px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }
  }
}

.header-item-container {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .header-item {
    height: 51px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;

    &--border {
      border-left: 1px solid #24242226;
      border-right: 1px solid #24242226;

    }
  }
}


.category-wrapper {
  background: #000000;
  .category-container {
    background-color: #fff;
    border-bottom: 1px solid rgba(36, 36, 34, 0.15);

  }
}

.faq-container {
  margin-right: 60px;
  height: calc(100dvh - 361px - 130px) !important;
  margin-top: 100px;
}
