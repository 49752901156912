.tg-container {
  .tg-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    margin-bottom: 10px;

    span {
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      font-family: 'Poppins';
      line-height: 1;
      text-transform: uppercase;
      max-width: 95%;
    }
  }

  .tg-breaker {
    height: 1px;
    width: 100%;
    background: rgba(45, 41, 39, 0.50),
  }

  .tg-body {
    margin-top: 30px;
    width: 950px;
    p, ol {
      margin: 0;
    }
  }

  .tg-body > *,
  .tg-body ol li {
    color: #000000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.5px;
    white-space: pre-line;
  }
}
