/** @format */

.wrap-bottom-navigation {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 49px;
  z-index: 111;
  display: flex;
  align-items: center;
  padding-left: 40px;
  border-top: 1px solid rgba(36, 36, 34, 0.15);
  background: #fff;

  div {
    z-index: 1;
  }
  .text-black {
    color: #000000;
  }
  &::after {
    content: "";
    // background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) , transparent);
    height: 170px;
    width: 100%;
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .left-group-btn {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    font-family: sans-serif;

    .btn-item {
      padding: 0px 0px;
      display: block;
      cursor: pointer;
      margin-right: 20px !important;
      svg {
        width: 24px;
      }
    }

    .locale-btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn-end {
      color: #242422;
      leading-trim: both;
      text-edge: cap;
      cursor: pointer;

      /* New/Menu Header */
      font-family: "Suisse Intl";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }
  }

  .right-group-btn {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    gap: 26px;
    .btn-item {
      height: 50px;
      display: flex;
      padding: 0px 22.5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      &.star {
        background: #000;
      }
      &.cms {
        padding: unset;
        padding-right: 40px;
      }
      svg {
        width: 22.849px;
        height: 22px;
      }
    }

    color: #ffffff;
  }
}

.wrap-bottom-navigation.light {
  background: #fcfcfc;
}

.wrap-bottom-navigation.is-show-floorplan {
  background: #fcfcfc;
}

.text-customer, .select-profile-btn {
  color: #242422;
  text-align: right;
  leading-trim: both;
  text-edge: cap;

  /* New/Menu Header */
  font-family: "Suisse Intl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.2px;
  text-transform: uppercase;

  margin-left: 20px;
}

.select-profile-btn {
  cursor: pointer;
}

.outline {
  z-index: 61;
  position: absolute;
  bottom: 80px;
  height: 1px;
  background: rgba(45, 41, 39, 0.5);
  width: calc(100% - 216px);
  left: 108px;
}
