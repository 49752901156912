/** @format */

.media-popup-gallery {
  position: fixed;
  inset: 0;
  background: #333333e6;
  z-index: 1000;

  .popup-gallery-wrapper,
  .popup-gallery-swiper {
    height: 100%;
    width: 100%;
  }

  .popup-gallery-wrapper {
    position: relative;
  }

  .popup-gallery-close {
    position: absolute;
    z-index: 1002;
    width: 26px;
    height: 24px;
    cursor: pointer;
    top: 50px;
    right: 50px;
  }

  .popup-gallery-navigation {
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 1001;
    justify-content: center;
    cursor: pointer;

    svg {
      position: absolute;
    }

    &.left {
      left: 81px;
      padding: 0;
    }

    &.right {
      right: 81px;
      padding: 0;
    }
  }

  .container-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: transparent;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.media-popup-view-line {
  z-index: 110;
}
