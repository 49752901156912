.ppg-page {
    &.static-page-wf {
      margin-top: 0;
      margin-bottom: 0;
      height: 100dvh;
      // width: 100vw;
      overflow-y: scroll;
      z-index: 3;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      padding-bottom: 49px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #242422;
        width: 100%;
        top: 48px;
        position: fixed;
        z-index: 2;
        background-color: #fff;

        &.presentation {
            top: 0;
        }

        .header-item {
            height: 51px;
            flex-basis: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #24242226;
            border-top: unset;
            border-right: none;
            font-size: 12px;
            font-weight: 400;
            position: relative;

            &--active::before {
                border-radius: 100%;
                position: absolute;
                background: black;
                bottom: 10px;
                height: 4px;
                width: 4px;
                content: "";
            }
        }
    }

    .content-wrapper {
        display: flex;  
        width: 100vw;
        scroll-margin-top: 100px;
        justify-content: center;
        border-bottom: 1px solid #24242226;
        height: 700px;
        flex-flow: row-reverse;

        &.bg-white {
            background: #fff;
        }
        .content-1,
        .sub-content {
            width: 1366px;
        }
        &.content-div {
            height: 768px;
        }
    }

    .content-1 {
        color: #000;
        display: flex;
        padding: 300px 232.5px 165.366px 233.5px;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .landing-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 40px;

            .landing-header {
                font-family: 'Domaine Sans Display';
                font-size: 75px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 57.6px */
                text-transform: uppercase;
            }

            .landing-content {
                display: flex;
                width: 900px;
                flex-direction: column;
                border-bottom: 1px solid #C3E4EB;
                margin: 45px 0;

                .landing-row {
                    display: flex;
                    height: 150px;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                    border-top: 1px solid #C3E4EB;

                    &.developer {
                        justify-content: center;
                    }

                    .credit-box {
                        display: flex;
                        width: 270px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 16px;

                        .sub-title {
                            font-family: 'Suisse Intl';
                            font-size: 12px;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 1.2px;
                            text-transform: uppercase;
                        }

                        .title {
                            font-family: 'Domaine Sans Display';
                            font-size: 24px;
                            font-weight: 400;
                            line-height: 115%; /* 27.6px */
                            text-transform: uppercase;
                        }
                    }
                }
            }

        }

        .ct-image-logo {
            width: 40px;
            height: 36.634px;
            background: transparent
        }
    }

    .sub-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 768px;
        justify-content: center;
        gap: 40px;
        align-self: stretch;

        &.architect {
            color: #FFF;
            padding: 213px 167px 212.557px 147px;
            .content-container hr { background: #FFF; }
        }

        &.interior-design {
            background-color: #FFF;
            color: #000;
            padding: 241px 167px 240.557px 147px;
            .content-container hr { background: #76999A; }
        }

        &.landscaping {
            background-color: #E4FFFD;
            color: #000;
            padding: 237px 167px 236.557px 147px;
            .content-container hr { background: #C3E4EB; }
        }

        .content-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: stretch;
            padding: 40px;
            padding-left: 88px;
            flex-direction: row;

            &.reverse {
                padding-left: 40px;
                padding-right: 88px;
                flex-direction: row-reverse;
            }

            // gap: 175px;

            .section-title-container {
                display: flex;
                align-items: center;
                margin-top: 20px;
                margin-bottom: 60px;

                .section-title {
                    margin: 0;
                    font-family: 'Suisse Intl';
                    padding-top: 2px;
                    font-weight: 400;
                    line-height: 14px;
                    font-size: 12px;
                    color: #242422;
                    text-transform: uppercase;
                }

                .dot {
                    width: 8px;
                    height: 8px;
                    background-color: #242422; 
                    border-radius: 50%;
                    display: inline-block; 
                    margin-right: 15px;
                }
            }

            img {
                width: 708px;
                height: 688px;
                object-fit: cover;
                @media screen and (max-width: 1024px){
                    width: 470px;
                }
            }
            

            hr {
                padding: 0;
                margin: 0;
                opacity: 0.4;
                border: none;

                &.breaker-left {
                    width: 50px;
                    height: 1.5px;
                }

                &.breaker-right {
                    width: 100%;
                    height: 1px;
                }
            }

            .l-wrapper {
                width: 450px;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    height: calc(100% + 40px + 40px);
                    width: 1px;
                    background-color: #24242226;
                    top: -40px;
                    right: -40px;
                    position: absolute;
                    
                    @media screen and (max-width: 1024px){
                        width: 0px;
                    }
                }

                @media screen and (max-width: 1024px){
                    width: 470px;
                }
            }

            .r-wrapper {
                width: 450px;
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    height: calc(100% + 40px + 40px);
                    width: 1px;
                    background-color: #24242226;
                    top: -40px;
                    left: -40px;
                    position: absolute;

                    @media screen and (max-width: 1024px){
                        width: 0px;
                    }
                }

                @media screen and (max-width: 1024px){
                    width: 470px;
                }
            }

            .c-sub-title {
                padding: 32px 0 16px;
                font-family: 'Suisse Intl';
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1.2px;
                text-transform: uppercase;
            }

            .c-title {
                font-family: 'Domaine Sans Display';
                font-size: 52px;
                font-weight: 400;
                line-height: 50px;
                text-transform: uppercase;
                color: #242422;

                div {
                    font-family: 'Domaine Sans Display';
                    font-size: 52px;
                    font-weight: 400;
                    line-height: 50px;
                    text-transform: uppercase;
                    color: #242422;
                }
            }

            ul.c-info-list {
                padding: 32px 0 0;
                list-style: none;
                margin-left: 0;
                margin-bottom: 0;
                padding-left: 0;
                display: flex;
                flex-direction: column;

                li.c-info-item {
                    padding-left: 33px;
                    text-indent: -28px;
                    font-family: 'Suisse Intl';
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #242422B2;

                    &:before {
                        content: "-";
                        padding-right: 20px;
                    }


                    // &:not(:last-child) {
                    //     margin-bottom: 32px;
                    // }
                }
            }
        }

        .ct-image-list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .ct-image {
                width: 344px;
                height: 256.268px;
                flex-shrink: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }
}