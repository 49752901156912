.gallery-landing {
  margin-top: 0px !important;

  &.static-page-wf {
    height: 100%;
  }

  &.presentation {
    .gallery-nav {
      margin-top: 0;
    }
    .gallery-content {  
      height: calc(100svh - 70px);
    }

  }

  .bottom-top {
    position: fixed;
    height: 1px;
    bottom: 80px;
    width: calc(100% - 216px);
    left: 108px;
    background: #2d292780;
  }

  .abs-bottom {
    padding: 0 108px;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
  }

  .gallery-nav {
    display: flex;
    position: absolute;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 49px;

    .sticky-nav-body {
      display: flex;
      height: 19px;
      justify-content: center;
      align-items: flex-start;
    }

    .sticky-nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 51px;
      border-left: 1px solid rgba(36, 36, 34, 0.15);
      border-bottom: 1px solid rgba(36, 36, 34, 0.15);
      border-top: unset;
      background: #FFF;
      width: 100%;
      &:first-child {
        border-left: unset;
      }

      // add small dot below active text
      &.active::before {
        border-radius: 100%;
        position: absolute;
        background: black;
        bottom: 10px;
        height: 4px;
        width: 4px;
        content: '';
      }

      .sticky-nav-text {
        color: #242422;
        leading-trim: both;
        text-edge: cap;

        /* New/Menu Header */
        font-family: "Suisse Intl";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.2px;
        text-transform: uppercase;
      }
    }
  }

  .content {
    padding: 60px 0;
    background: #fcfcfc;
  }

  .tab-wrapper {
    width: 100%;
    padding: 5px 0;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .tab {
      display: flex;
      flex-direction: row;

      .btn-tab {
        cursor: pointer;
        width: 128px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        .tab-border {
          width: 100%;
          background: transparent;
          height: 3px;
          margin-top: 8px;
          justify-content: center;
          align-items: center;
          display: flex;

          .line-border {
            height: 1px;
            width: 100%;
            background: #000000;
          }

          .active {
            background: #59899d;
            height: 3px;
          }
        }
      }
    }
  }

  .gallery-content {
    height: 100svh;
    padding-bottom: 49px;
    overflow-y: scroll;
    margin-top: 100px;
  }

  .gallery-content-presentation {
    margin-top: 51px;
  }
}
