.services-page {
  .bottom-top {
    position: fixed;
    height: 1px;
    bottom: 80px;
    width: calc(100% - 216px);
    left: 108px;
    background: #2d292780;
  }

  .main-content {
    position: static;
    width: 100%;
    background-color: #ffffff;

    .content-1 {
      display: flex;
      padding: 100px 246px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .aw-link {
        color: #6b8fa3;
        font-size: 13px;
        font-family: Adobe Caslon Pro;
        letter-spacing: 1.56px;
        text-transform: uppercase;
        text-decoration: none;
        vertical-align: center;
        display: flex;
        align-items: center;
      }

      .header {
        color: #01344b;
        font-size: 48px;
        font-family: Adobe Caslon Pro;
        letter-spacing: 2.64px;
        text-transform: uppercase;
      }
    }

    .content-4 {
      padding: 0 108px 75px 108px;

      .tg-container {
        .tg-header {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;

          color: #01344b;
          font-size: 30px;
          font-family: Adobe Caslon Pro;
          letter-spacing: 2.1px;
          text-transform: uppercase;

          margin-bottom: 10px;
        }

        .tg-body {
          margin-top: 30px;
        }

        .tg-body > * {
          color: #2d2927;
          font-family: Proxima Nova;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.9px;
        }
      }
    }

    .content-5,
    .content-7,
    .content-9,
    .content-11 {
      padding: 50px 108px 50px 108px;
      margin-bottom: 50px;
      background: #eee;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:not(.show) {
        display: none;
      }

      .ctg-col {
        flex: 1;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 22px;
        flex-shrink: 0;
        max-width: 316px;

        li,
        li > div {
          color: #000;
          font-size: 18px;
          font-weight: 400;
          font-family: Proxima Nova;
          line-height: 150%;
          letter-spacing: -0.9px;
        }
      }
    }

    .content-9 {
      .content .content-message {
        .paragraph {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 84px;
          grid-row-gap: 50px;

          p {
            padding-bottom: 6px;
          }

          strong {
            color: #01344b;
            font-family: Adobe Caslon Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
          }

          ul {
            display: flex;
            flex-direction: column;

            &:first-of-type {
              padding-bottom: 26px;
            }

            li,
            li p {
              color: #000;
              font-family: Proxima Nova;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.9px;
            }
          }
        }
      }
    }

    .content-5 .privileged {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 84px;
      row-gap: 22px;

      li {
        color: #000;
        font-family: Proxima Nova;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.9px;
      }
    }

    .content-7 {
      display: flex;
      gap: 84px;

      .column {
        width: 50%;

        li {
          margin-bottom: 22px;
          color: #000;
          font-family: Proxima Nova;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.9px;
        }
      }
    }

    .content-6 {
      padding: 0 108px 75px 108px;

      .tg-container {
        .tg-header {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;

          color: #01344b;
          font-size: 30px;
          font-family: Adobe Caslon Pro;
          letter-spacing: 2.1px;
          text-transform: uppercase;

          margin-bottom: 10px;
        }

        .tg-body {
          max-width: 750px;
          margin-top: 30px;
        }

        .tg-body > * {
          color: #2d2927;
          font-family: Proxima Nova;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.9px;
        }
      }
    }

    .content-8,
    .content-10 {
      padding: 0 108px 75px 108px;

      .tg-container {
        .tg-header {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;

          color: #01344b;
          font-size: 30px;
          font-family: Adobe Caslon Pro;
          letter-spacing: 2.1px;
          text-transform: uppercase;

          margin-bottom: 10px;
        }

        .tg-body {
          max-width: 750px;
          margin-top: 30px;
        }

        .tg-body > * {
          color: #2d2927;
          font-family: Proxima Nova;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.9px;
        }
      }
    }
  }
}
