.wrap-floor-plan-gallery {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 60;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  .react-transform-wrapper {
    height: 100% !important;
    width: 100vw !important;
    .react-transform-component {
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.90);
    }

    .floorplan-item {
      width: 100%;
      height: 70vh;
      object-fit: contain;

      &.img-not-found {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }

    .slick-slide {
      border: none;
    }
  }
}

.wrap-list-floor {
  .list-group {
    position: absolute;
    width: 100%;
    bottom: 0;

    .list-group-item {
      text-decoration: underline;
      font-size: 14px;
      color: #000;
      opacity: 1;
      font-weight: 300;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
    }
  }
}

.close-btn-floor {
  position: absolute;
  z-index: 110;
  right: 70px;
  top: 130px;
  cursor: pointer;
}

.wrap-zoom-btn {
  position: absolute;
  bottom: 98.5px;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 61;

  .m-none {
    margin: 0 !important;
  }

  div {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin: 0 5px;
    span {
      color: #76999a;
      font-family: "Suisse Intl";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.56px;
      text-transform: uppercase;
    }
  }
  img {
    cursor: pointer;
  }
}
