@import "../../common";

.wrap-sidenav {
  position: absolute;
  z-index: 1001;
  width: fit-content;
  height: calc(100svh - 122px - 122px);
  min-height: 780px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 0;

  .sidenav {
    width: 225px;
    border-left: 1px solid rgba(211, 208, 209, 0.2509803922);
  }

  > div {
    height: 100%;
  }

  .sidenav__heading {
    padding: 0;
    margin-bottom: 30px;
    letter-spacing: 0.07rem;

    h2 {
      color: #242422;
      -webkit-text-stroke-width: 0.25;
      -webkit-text-stroke-color: #242422;
      font-family: "Domaine Sans Display";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: 0px !important;
    }
  }

  .sidenav__body {
    border-top: 1px solid rgba(211, 208, 209, 0.25);
    justify-content: start;
    line-height: 2.188;
    height: calc(100vh * (800 / 1024) - 100px);
  }

  .sidenav__footer {
  }

  .sidenav--dark {
    background: rgba(#343434, 0.9);

    .list-group-item {
      &:hover {
        opacity: 1;
      }
    }

    .list-group-heading {
      color: #ababab;
    }
  }

  .wrap-btn-detail .list-group .list-group-item,
  .list-group-item {
    padding: 0 0.75rem;
  }

  .list-group-item {
    color: #242422;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%; /* 28px */

    padding: 0 0;
    opacity: 1;
    &.list-group-heading {
      font-weight: 500;
    }

    &.active {
      opacity: 1;
      background-color: #000;
      color: #fff;
      border-radius: 0px;
      font-weight: 400;
    }
  }

  .list-group-heading {
    cursor: initial;
    flex-basis: 100%;
    letter-spacing: 0.12em;
  }

  .list-group-horizontal .list-group-item:not(.list-group-heading) {
    flex-basis: 30%;
  }

  #filter {
    position: relative;
    padding: 30px 35px;
    background-color: #ffffff;

    .sidenav__body {
      border-top: 1px solid rgba(211, 208, 209, 0.25);
      gap: 25px;
      overflow-x: hidden;
      // padding-bottom: 62px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .sidenav__body::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    .filter-panel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      align-self: stretch;
      padding-top: 20px;
    }

    .filter-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;
      align-self: stretch;

      &.aspect {
        font-weight: 400;

        .name {
          font-size: 13px;
          font-family: "Proxima Nova";
        }

        .description {
          font-size: 12px;
          color: #797979;
        }
      }

      .heading {
        color: #242422;
        /* ✓Section breaks */
        -webkit-text-stroke-width: 0.25;
        -webkit-text-stroke-color: #242422;
        font-family: "Suisse Intl";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      // hack for last option of availability only.
      .list-group.availability {
        &:last-child .pretty {
          white-space: pre-wrap;
          & label::after,
          & label::before,
          .svg {
            top: 0px;
          }
          & .pretty input[type="checkbox"]::before,
          & .pretty input[type="checkbox"]::after {
            width: calc(1em + 1px);
            height: calc(1em + 1px);
          }
          & label {
            text-indent: 0;
            padding-left: 16px;
            text-transform: capitalize;
            color: #242422;

            /* New/Body */
            font-family: "Suisse Intl";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.36px;
            width: 100%;
          }
        }
      }
      .list-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        align-self: stretch;

        .list-group-item {
          border: 1px solid #e4e4e4;
          font-size: 14px;
          opacity: 1;

          &.border-none {
            border: none;
            color: #fff;
            /* ✓Links and Categories */
            font-family: "Suisse Intl";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 214.286% */
            letter-spacing: 1.4px;
            display: flex;
          }
          label {
            color: #242422;
            /* ✓Links and Categories */
            font-family: "Suisse Intl";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 1.4px;
            margin-left: 16px;
            text-indent: 16px;
          }
        }

        .list-group-number-item {
          cursor: pointer;
          border: 1px solid #e4e4e4;
          //padding: 1px 0;
          width: 23px;
          height: 23px;
          text-align: center;
          list-style-type: none;

          color: #fff;
          font-family: Proxima Nova;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;

          &.active {
            background-color: #ffffff;
            color: #2d2927;
          }
        }

        .list-group-item-btn {
          cursor: pointer;
          margin-right: 5px;
          padding: 5px 10px;
          border: 1px solid #e4e4e4;
          text-align: center;
          list-style-type: none;

          color: #fff;
          font-family: Proxima Nova;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 17.5px;
          flex-grow: 1;

          &.active {
            background-color: #ffffff;
            color: #2d2927;
          }
        }
      }

      .input-with-label {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        flex: 1 0 0;
        background-color: transparent;
        border: 1px solid #ced4da;
        padding: 5px 10px 5px 10px;

        input::placeholder {
          color: #ffffff;
          opacity: 1;
        }

        .form-control {
          color: #242422;
          background-color: transparent;
          border: none;
          padding: 0;
          font-size: 14px;
          letter-spacing: 1.4px;
          font-style: normal;
          font-weight: 400;
          line-height: 1;
          text-transform: uppercase;
        }

        .input-label {
          font-size: 10px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: 1px;
          font-family: "Suisse Intl";
          color: rgba(36, 36, 34, 0.15);
        }
      }
      .wrapper-price {
        flex-direction: column;
        gap: 5px !important;
      }
      .wrapper-size {
        gap: 5px !important;
      }
    }
    .btn-bottom {
      cursor: pointer;
      z-index: 9;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: flex-start;
      width: 83%;

      span {
        color: #242422;
        /* ✓Links and Categories */
        font-family: "Suisse Intl";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        margin: 0 !important;
      }

      .disable {
        opacity: 0.15;
      }
    }
  }

  #lots {
    padding: 30px 25px;
    background-color: #ffffff;

    .sidenav__body {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .sidenav__body::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    .list-group {
      padding-top: 20px;
      padding-bottom: 50px;
      border-radius: 0;
    }

    .wrap-show-filter-btn {
      position: absolute;
      bottom: 0;
    }

    .btn-bottom {
      cursor: pointer;
      position: absolute;
      bottom: 1px;
      left: 0;
      line-height: 1;
      // background-color: #01344B;
      padding: 0 0 30px 25px;
      z-index: 9;

      .show-filter {
        opacity: 0.15;
      }

      span {
        color: #242422;
        /* ✓Links and Categories */
        font-family: "Suisse Intl";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1; /* 214.286% */
        letter-spacing: 1.4px;
        text-transform: uppercase;
      }
    }

    .list-group-item {
      &.active {
        background-color: transparent;
      }
    }
  }
}

.wrap-sidenav--right {
  left: unset;
  right: 0;
  width: 285px;
}

.wrap-btn-detail {
  width: 100%;
  text-align: left;
  color: #fff;

  .wrap-btn-detail-img {
    width: 85px;
    height: 40px;
    margin: 50px 0 60px 0;
  }

  .list-group {
    gap: 15px;
    .list-group-item {
      font-family: "Suisse Intl";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1; /* 214.286% */
      letter-spacing: 1.4px;
      text-transform: uppercase;

      cursor: pointer;
      opacity: 1;
      margin: 0 !important;
    }
  }
}

.wrap-btn-show-filter {
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  font-weight: 400;
  left: 0;
  right: 0;
  bottom: -2rem;
  z-index: 1120;

  p {
    margin: 0;

    b {
      color: #fff;
      text-decoration: underline;
    }
  }
}

#sidenavDetail {
  display: flex;
  width: 285px;
  padding: 30px 35px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  background: #ffffff;
  height: 100%;

  .sidenav {
    &__heading {
      color: #242422;

      /* Panel headers */
      font-family: Domaine Sans Display;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 36px */
      text-transform: uppercase;
      align-self: stretch;
    }
    &__body {
      border: none;
      .list-group {
        padding-left: 30px;
        padding-right: 30px;
      }

      .list-group-item {
        padding: 0;
        opacity: 1;
      }

      & > .list-group .list-group-item {
        cursor: initial;
        border-top: 1px solid rgba(211, 208, 209, 0.25);
        margin: 0 0 20px 0;
      }

      .list-group-item-title {
        padding: 15px 0;
        line-height: 1; // text-edge: cap;
        display: flex;
        justify-content: center;
        gap: 14px;
        .dot-before {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #000;
          margin-top: 2px;
        }
        span {
          color: #242422;

          /* New/Body */
          font-family: "Suisse Intl";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1;
          letter-spacing: -0.36px;
        }
      }

      .list-group-item-body {
        span {
          color: #242422;
          font-family: Suisse Intl;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 1;
        }
      }

      .list-group-item:last-child {
        border-radius: 0;
      }
    }
  }

  &__heading h2 {
    font-family: Adobe Caslon Pro;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.89px;
    text-transform: uppercase;
    padding-bottom: 0px;
  }
}

.accordion-filter {
  .list-group-heading {
    font-size: 12px;

    &::after {
      content: "+";
      display: inline-block;
      margin-left: 0.5rem;
      margin-top: -3px;
    }

    &.open::after {
      content: "-";
    }
  }
}

.accordion-sidebar .service-charge {
  margin-top: 50px;

  b {
    font-family: "Proxima Nova";
  }

  span {
    text-transform: none;
    font-size: 12px;
    font-family: "Proxima Nova";
  }
}

.accordion-sidebar .room-area {
  margin-top: 50px;

  .list-group-item-title {
    display: none;
  }

  .list-group-item-body {
    text-transform: none !important;
  }

  .room-area-list {
    color: #fff;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    list-style-type: none;

    li {
      margin-top: 10px;
      padding-left: 10px;
      display: flex;

      &::before {
        content: "• ";
        padding-top: 3px;
      }

      .content {
        margin-left: 5px;
        word-break: break-word;
        max-width: 170px;

        span {
          white-space: nowrap;
        }
      }
    }
  }
}

.content .content-message .dropdown {
  width: 100%;
}
.scroll-unit {
  overflow-y: scroll;
  height: calc(95% - 30px);
}
.scroll-filter {
  overflow-y: scroll;
}
.scroll-detail {
  overflow-y: scroll;
}

.disable-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.disable-scroll-bar::-webkit-scrollbar {
  display: none; // Safari and Chrome
}
