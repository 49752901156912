.neighborhood-page {
    &.static-page-wf {
        margin-top: 49px;
        height: calc(100dvh - 49px);
        background-color: #FFFFFF;
    }

    .main-content {
        width: 100%;
        background-color: #FFFFFF;
        margin-bottom: 47px;

        .content-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            
        }
        .content-1-wrapper {
            background: #E4FFFD;
        }

        .content-1 {
            display: flex;
            width: 1366px;
            padding: 114px 209px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            position: relative;


            .ct-image-1 {
                position: absolute;
                top: 0;
                right: 0;
            }

            .residence-info {
                display: flex;
                width: 521px;
                flex-direction: column;
                align-items: flex-start;

                .residence-para {
                    display: flex;
                    width: 948px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 50px;
                    .title {
                        color: #242422;

                        /* New/Header */
                        -webkit-text-stroke-width: 0.25;
                        -webkit-text-stroke-color: #242422;
                        font-family: "Domaine Sans Display";
                        font-size: 75px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; /* 90px */
                        text-transform: uppercase;
                    }
                    .para {
                        align-self: flex-end;
                        width: 500px;
                        color: #242422;
                        opacity: 0.6;
                        /* ✓Body */
                        font-family: "Suisse Intl";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px; /* 150% */
                    }
                }
            }
        }

        .content-body {
            position: relative;
            display: flex;
            height: 768px;
            padding: 40px 88px 40px 40px;
            justify-content: center;
            align-items: center;
            gap: 40px;
            flex-direction: row;
            width: 1366px;
            border-bottom: 1px solid rgba(36, 36, 34, 0.15);

            .content-info {
                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 60px;
                align-self: stretch;
                height: 648px;

                &.index-0 {
                    width: 479px;
                }
                
                &.index-1 {
                    width: 450px;
                }

                &.index-2 {
                    width: 579px;
                }

                .content-title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 14px;

                    color: #242422;

                    /* New/Menu Header */
                    font-family: "Suisse Intl";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                }

                .content-header {
                    white-space: pre-line;
                    color: #242422;

                    /* New/Header */
                    -webkit-text-stroke-width: 0.25;
                    -webkit-text-stroke-color: #242422;
                    font-family: "Domaine Sans Display";
                    font-size: 75px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%; /* 90px */
                    text-transform: uppercase;
                }
                
    
                .content-info-list {
                    display: flex;
                    width: 450px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
    
                    .info-text {
                        display: flex;
                        gap: 12px;
    
                        color: rgba(36, 36, 34, 0.70);
    
                        /* New/Body */
                        font-family: "Suisse Intl";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130%; // replace leading-trim and text-edge
                        letter-spacing: -0.36px;
    
                        &::before {
                            content: '-'
                        }
                    }
                }
            }

            .divider {
                width: 1px;
                height: 768px;
                background: rgba(36, 36, 34, 0.15);
            }

            .content-images {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                flex: 1 0 0;
                align-self: stretch;
                width: 679px;

                .content-image {
                    flex: 1 0 0;
                    align-self: stretch;
                    height: 100%;
                    background-size: cover;
                    background-position: 50%;
                    background-repeat: no-repeat;

                    &.index-0 {
                        width: 679px;
                    }

                    &.index-1 {
                        width: 708px;
                    }

                    &.index-2 {
                        width: 579px;
                    }
                }
            }


            &.reverse {
                flex-direction: row-reverse;
                padding: 40px 40px 40px 88px;
            }
            
        }
    }
}
