.wrap-gallery-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #5b5b5b;

  .gallery-header {
    position: absolute;
    top: 48px;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 15px 40px;
    justify-content: space-between;

    &.presentation {
      top: 0;
    }

    p {
      margin: 0;
      font-family: "Domaine Sans Display";
      font-size: 24px;
      font-weight: 400;
      line-height: 25px;
    }

    .close-icon {
      cursor: pointer;
    }
  }

  .slick-slider {
    max-height: 100vh;
    overflow: hidden;

    .gallery-item {
      width: 100vw;
      height: 100vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;

      &::after {
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
        display: block;
        height: 200px;
        width: 100%;
        position: absolute;
        bottom: 0;
      }

      .gallery-hotspot {
        position: absolute;
        z-index: 100;
        cursor: pointer;
        width: 30px;
      }
    }
  }
  .slick-dots {
    bottom: 90px;

    li {
      margin: 0 2px;

      button:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #fff;
        }
      }
    }
  }
}

.btn-gallery {
  display: block;
  position: absolute;
  top: calc(50% - 26px);
  cursor: pointer;
}

.btn-gallery.prev-slide {
  left: 95px;
  color: rgba(255, 255, 255, 0.6);
}

.btn-gallery.next-slide {
  right: 93px;
  color: rgba(255, 255, 255, 0.6);
}

.wrap-create-gallery-modal {
  .modal-content {
    border-radius: 0px;
  }

  .content {
    margin-bottom: 32px;

    .form-control {
      border-radius: 0px;
    }
  }

  .modal-form__title {
    font-size: 20px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold !important;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 14px;
  }

  button {
    border: 0px;
    padding: 0px;
    text-decoration: underline;
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
  .wrap-select-gallery-type {
    .css-1s2u09g-control {
      border-radius: 0;
    }
  }
}
.wrap-media-picker {
  height: 400px;
  overflow-y: scroll;
  .list-group {
    border-radius: 0;
    .list-group-item {
      border: 1px solid #c1c1c1;
    }
    .row {
      align-items: center;
    }
  }
}
.close-btn {
  width: 100%;
  position: absolute;
  top: 100px;
  right: 70px;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }

  img {
    position: absolute;
    right: 0;
  }
}

.wrap-close-btn {
  position: absolute;
  bottom: 122px;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  div {
    display: flex;
    gap: 10px;
    flex-direction: row;
    cursor: pointer;
    span {
      color: #fff;

      /* ✓Links and Categories */
      font-family: Suisse Intl;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 214.286% */
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }
    .rotate-img {
      transform: rotate(180deg);
    }
  }
}
