.accordion-sidebar {
  &-button {
    background: transparent !important;
    color: #ffffff !important;
    padding: 0 0 15px 0 !important;
    box-shadow: unset !important;
    position: relative;
    border-bottom: 1px solid #d3d0d140 !important;
    color: #fff;

    /* Form & Header */
    font-family: Suisse Intl;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 214.286% */
    letter-spacing: 1.4px;
    text-transform: uppercase;
    &-icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 12px;

      &-up {
        transform: rotate(180deg);
      }
    }

    &:focus-visible {
      outline: none;
    }

    &:focus {
      box-shadow: unset !important;
    }
  }
  .list-group {
    padding: 0 !important;

    gap: 30px;
    &-item {
      &-title {
        font-weight: 400;
      }
      padding-bottom: 0px !important;
      border-top: 1px solid rgba(36, 36, 34, 0.15) !important;

      .list-group-item-body {
        line-height: 1;
      }
    }
  }
  .accordion-button:not(.collapsed)::after {
    display: none;
  }
  .accordion-button {
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    color: #242422 !important;
    align-items: flex-end;

    span {
      color: #242422;

      /* New/Panel Section */
      font-family: "Suisse Intl";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 1.4px;
      text-transform: uppercase;;
    }

    padding-bottom: 25px !important;
  }
  .accordion-button-icon {
    display: flex;
    padding: 13px 0px 1px 0px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}
