#customer-profile {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .error-message {
    margin-bottom: 0;
  }

  .content-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    .title {
      color: #fff;
      text-align: center;
      /* Header */
      font-family: Domaine Sans Display;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 43.2px */
      text-transform: uppercase;
    }
    .sub-title {
      color: #fff;
      text-align: center;
      /* Body */
      font-family: Suisse Intl;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      margin-top: 15px;
    }
    .create-profile {
      color: #aaf9f6;
      /* Footer Links */
      font-family: Suisse Intl;
      font-size: 13px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .first-last-name {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 25px;
    .form-info {
      display: flex;
      flex-direction: column;
      width: 267px;
      border-bottom: 1px solid #fff;
      padding-bottom: 13px;
      label {
        color: #fff;
        text-align: center;
        /* Form & Header */
        font-family: Suisse Intl;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 214.286% */
        letter-spacing: 1.4px;
        text-transform: uppercase;
        height: 20px;
      }
      input, select {
        border-radius: 0;
        border: 0;
        padding: 0 !important;
        background: transparent;
        font-size: 14px;
        height: 15px;
        margin-top: 15px;
        color: white;
        font-family: Suisse Intl;
        text-align: center;
        line-height: 30px; /* 214.286% */
        letter-spacing: 1.4px;

        &:focus {
          box-shadow: none;
        }
        &::placeholder, .placeholder {
          color: rgba(255, 255, 255, 0.6);
          text-transform: uppercase;
          font-size: 14px;
          font-family: Suisse Intl;
        }
      }
    }
  }
  .wrap-button-list-user {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-cancle {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .button-group {
    width: 100%;
    justify-content: space-between;

    .explore-btn {
      display: flex;
      align-items: center;
      gap: 10px;
      .mr-0 {
        margin-right: 0 !important;
      }
    }

    button {
      color: #242422;
      font-family: "Suisse Intl";
      font-size: 12px;
    }
  }
}
