.wrap-video-intro {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: black;

  img.play {
    position: absolute;
    top: calc(50% - 80px);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img.intro {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .btn-skip-video {
    left: 50%;
    transform: translateX(-50%);
    bottom: 106px;
    position: absolute;
    display: flex;
    gap: 10px;
    background: transparent;
    border: none;

    &:hover {
      background-color: unset;
    }

    span {
      color: #FFF;
      font-family: 'Suisse Intl';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 214.286% */
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }
  }
}
